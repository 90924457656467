import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card, Heading, Flex, Paragraph } from 'reakit'

import {
	Container,
	Form,
	Body,
	ScrollableContainer,
	TopBar,
	HomeFooter
} from '../components/layouts'

import {
	OrganizationsForm,
	ProjectsForm,
	Loader,
} from '../components/ui'

import Layout from '../components/layout'

import {
	clear,
	register,
	notifyMessage,
	getOrgs,
	getProjects,
	setOrg,
	selectOrg,
	selectProj,
	selectProjects,
	setProjects,
	clearOrgAndProj,
	clearProjects,
	clearSelectedProj,
	clearErrors,
} from '../factory'

import theme from '../theme/content'

class Register extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			position: 0,
			pending: false,
			matchingOrgs: [],
			selectedOrg: null,
			selectedProjects: [],
			selectedDonation: 0.5,
			validatedForm: false,
		}

		this.setRegisterValues = this.setRegisterValues.bind(this)
		this.submitRegisterValidate = this.submitRegisterValidate.bind(this)
		this.submitRegisterForm = this.submitRegisterForm.bind(this)
		this.registerSuccess = this.registerSuccess.bind(this)
		this.searchOrgs = this.searchOrgs.bind(this)
		this.selectOrg = this.selectOrg.bind(this)
		this.selectProjects = this.selectProjects.bind(this)
		this.nextPosition = this.nextPosition.bind(this)
		this.prevPosition = this.prevPosition.bind(this)
		this.handleSkip = this.handleSkip.bind(this)
		this.resetPosition = this.resetPosition.bind(this)
	}

	componentDidMount() {
		this.props.onGetOrgs()
		this.props.clear()
		this.props.clearErrors()
		this.props.onClearOrgAndProj()
		this.props.onClearProjects()
	}

	setRegisterValues(e) {
		if (e.target) {
			this.setState({
				[e.target.name]: e.target.value,
			})
		}
	}

	submitRegisterValidate() {
		if (!this.state.f_name) {
			this.setState({error: 'First name must be populated.'})
			return false
		} else if (!this.state.l_name) {
			this.setState({error: 'Last name must be populated.'})
			return false
		} else if (!this.state.email && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
			this.setState({error: 'Email must be populated with the correct format.'})
			return false
		} else if (!this.state.phone) {
			this.setState({error: 'Phone name must be populated.'})
			return false
		} else if (!this.state.password) {
			this.setState({error: 'Password must be populated.'})
			return false
		} else if (!this.state.password_confirm) {
			this.setState({error: 'Re-enter password must be populated.'})
			return false
		} else if (this.state.password !== this.state.password_confirm) {
			this.setState({error: 'Passwords must match.'})
			return false
		} else if (!this.state.tos_agree) {
			this.setState({error: 'Please agree to the Privacy Policy and Terms & Conditions'})
			return false
		}

		return true
	}

	registerSuccess() {
		this.setState({error: ''})
		this.nextPosition()
	}

	submitRegisterForm(e) {
		e.preventDefault()

		if (this.submitRegisterValidate()) {
			this.props.registerAccount(
				this.state.email,
				this.state.password,
				this.state.f_name,
				this.state.l_name,
				this.state.phone,
				this.props.pageContext.clientIp,
				this.registerSuccess
			)
		}
	}

	searchOrgs(e) {
		const orgs = this.props.organizations


		if (!orgs || !orgs.length) return

		const filteredOrgs = orgs.filter(org => (
			org.title.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1
		))

		this.setState({
			matchingOrgs: filteredOrgs,
		})
	}

	selectOrg(org) {
		if (this.props.user) {
			this.setState({
				selectedOrg: org,
				pending: true,
			})

			this.props.onSelectOrg(org)
			this.props.onSetOrg(org.id, this.props.user.id, this.props.accessToken, () => {
				this.setState({
					pending: false,
				})
				this.props.onGetProjects(org.id)
				this.nextPosition()
			})
		}
	}

	selectProjects(projects) {
		if (this.props.user && projects && projects.length) {
			this.setState({
				pending: true,
			})

			this.props.onSetProjects(this.props.user.id, projects, this.props.accessToken, () => {
				this.setState({
					selectedProjects: projects,
					pending: false,
				})

				this.props.onSelectProjects(projects)
				this.nextPosition()
			})
		  }
	}

	nextPosition() {
		this.setState({
			position: this.state.position < 3 ? this.state.position + 1 : this.state.position,
		})
	}

	prevPosition() {
		this.setState({
			position: this.state.position > 0 ? this.state.position - 1 : this.state.position,
		})
	}

	handleSkip() {
		this.props.onClearOrgAndProj()
		this.props.onClearProjects()
		this.nextPosition()
	}

	resetPosition() {
		this.setState({
			position: 0,
		})
	}

	render() {
		const content = theme.pagesContent.register
		return (
			<Layout>
				<TopBar />
				<Body bgColor={theme.colors.wildSand} bg={content.bg} maxWidth="100%">
					<ScrollableContainer position={this.state.position} totalPositions={4}>
						<Container>
							<Flex width={'100vw'} row>
								<Flex
									column
									width={'100%'}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Card borderRadius="4px" padding="1em" width="40%">
										<Heading as="h1" margin="0 0 2rem 0">
											{content.title}
										</Heading>
										<Flex margin="0 0 1rem 0" column>
											<Form
												fields={content.registerForm.steps.first}
												setValues={this.setRegisterValues}
												submitForm={this.submitRegisterForm}
												submitBtnText={content.registerForm.submitField.text}
												error={this.props.authError || this.state.error}
												logging={this.props.authPending}
											/>
										</Flex>
									</Card>
								</Flex>
							</Flex>
						</Container>
						<Container>
							<Flex width={'100vw'} row>
								<Flex
									column
									width={'100%'}
									height={'100vh'}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Card borderRadius="4px" padding="1em" width="40%">
										<Heading as="h1" margin="0 0 2rem 0">Select an organization</Heading>
										<Flex margin="0 0 1rem 0" column>
											<Paragraph>Thanks for registering for Flourish! Pick a cause you are passionate about from our curated list of established organizations. If you would like to pick an organization at a later time, scroll down to skip.</Paragraph>
											<OrganizationsForm
												title={""}
												orgs={this.props.organizations}
												matchingOrgs={this.state.matchingOrgs}
												selectedOrg={this.props.selectedOrg}
												onSelectOrg={this.selectOrg}
												onChangeSearch={this.searchOrgs}
												back={this.prevPosition}
												skip={this.handleSkip}
												errors={JSON.stringify(this.props.donateError) || this.state.error}
											/>
										</Flex>
										{(this.props.donatePending && this.state.pending) ? <Loader /> : false}
									</Card>
								</Flex>
							</Flex>
						</Container>
						<Container>
							<Flex width={'100vw'} row>
								<Flex
									column
									width={'100%'}
									height={'100vh'}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Card borderRadius="4px" padding="1em" width="48.5%">
										<Heading as="h1" margin="0 0 2rem 0">Select a project</Heading>
										<Flex margin="0 0 1rem 0" column>
											<ProjectsForm
												projects={this.props.projects}
												selectedProjects={this.props.selectedProjects}
												onSelectProjects={this.selectProjects}
												next={this.nextPosition}
												skip={this.handleSkip}
												back={this.prevPosition}
												errors={JSON.stringify(this.props.donateError) || this.state.error}
											/>
										</Flex>
										{(this.props.donatePending && this.state.pending) ? <Loader /> : false}
									</Card>
								</Flex>
							</Flex>
						</Container>
						<Container>
							<Flex width={'100vw'} row>
								<Flex
									column
									width={'100%'}
									height={'100vh'}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Card borderRadius="4px" padding="1em" width="50%">
										<Heading as="h1" margin="0 0 2rem 0">Thank you for registering!</Heading>
										<Flex margin="0" column>
											<Paragraph>
												If you are an organization administrator please email <a href="mailto:support@flourishchange.com">support@flourishchange.com</a> to complete the sign up process.
            									Otherwise, please download our app for <a href="https://itunes.apple.com/us/app/flourish-change/id1356801492?mt=8">iOS</a> and <a href="https://play.google.com/store/apps/details?id=com.flourish.flourish">android</a> to begin leaving your impact!
											</Paragraph>
										</Flex>
									</Card>
								</Flex>
							</Flex>
						</Container>
					</ScrollableContainer>
				</Body>
				<HomeFooter />
			</Layout>
		)
	}
}

Register.propTypes = {
	pageContext: PropTypes.any,
	user: PropTypes.object,
	organizations: PropTypes.array,
	selectedOrg: PropTypes.object,
	selectedProj: PropTypes.object,
	selectedProjects: PropTypes.array,
	projects: PropTypes.array,
	onSetOrg: PropTypes.func,
	onGetOrgs: PropTypes.func,
	onGetProjects: PropTypes.func,
	onSelectOrg: PropTypes.func,
	onSelectProject: PropTypes.func,
	onSelectProjects: PropTypes.func,
	onSetProjects: PropTypes.func,
	onClearOrgAndProj: PropTypes.func,
	onClearProjects: PropTypes.func,
	onClearSelectedProj: PropTypes.func,
	clearErrors: PropTypes.func,
	registerAccount: PropTypes.func,
	clear: PropTypes.func,
	notifyMessage: PropTypes.func,
	accessToken: PropTypes.string,
	authError: PropTypes.string,
	authPending: PropTypes.bool,
	authSuccess: PropTypes.bool,
	donateError: PropTypes.any,
	donatePending: PropTypes.bool,
	donateSuccess: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		user: state.authState.user,
		accessToken: state.authState.accessToken,
		authError: state.authState.error,
		authPending: state.authState.pending,
		authSuccess: state.authState.success,
		organizations: state.donateNowState.organizations,
		selectedOrg: state.donateNowState.selectedOrg,
		projects: state.donateNowState.projects,
		selectedProj: state.donateNowState.selectedProj,
		selectedProjects: state.donateNowState.selectedProjects,
		donateError: state.donateNowState.error,
  	donatePending: state.donateNowState.pending,
  	donateSuccess: state.donateNowState.success,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onGetOrgs: () => dispatch(getOrgs()),
		onSetOrg: (orgId, userId, accessToken, callback) => dispatch(setOrg(orgId, userId, accessToken, callback)),
		onSelectOrg: (org) => dispatch(selectOrg(org)),
		onGetProjects: (orgId) => dispatch(getProjects(orgId)),
		onClearSelectedProj: () => dispatch(clearSelectedProj()),
		onSelectProject: (proj) => dispatch(selectProj(proj)),
		onSelectProjects: (projects) => dispatch(selectProjects(projects)),
		onSetProjects: (userId, projIds, accessToken, callback) => dispatch(setProjects(userId, projIds, accessToken, callback)),
		onClearOrgAndProj: () => dispatch(clearOrgAndProj()),
		onClearProjects: () => dispatch(clearProjects()),
		clearErrors: () => dispatch(clearErrors()),
		clear: () => dispatch(clear()),
		registerAccount: (email, password, firstName, lastName, phone, ipRegistered, callback) => dispatch(register(email, password, firstName, lastName, phone, ipRegistered, callback)),
		notifyMessage: (message) => dispatch(notifyMessage(message)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Register)
